import { FluentProvider } from "@fluentui/react-components";
import { QueryClient, QueryClientProvider } from "react-query";

import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import { TeamsFxContext } from "./Context";
import { useTeamsContext } from "../hooks/useTeamsContext";
import TeamcenterAuthPopup from "./authentication/TeamcenterAuthPopup";
import TeamcenterAuthentication from "./authentication/TeamcenterAuthentication";
import ProblemReports from "./problems/ProblemReportsList";
import TeamcenterError from "./common/TeamcenterError";
import ProblemDetails from "./problems/ProblemDetails";
import WorkflowTasks from "./workflows/WorkflowTasksList";
import ProblemReportInfo from "./problems/ProblemReportInfo";
import WorkflowTaskInfo from "./workflows/WorkflowTaskInfo";
import DataPrivacy from "./dataprivacy/DataPrivacy";
import DataPrivacyInitial from "./dataprivacy/DataPrivacyInitial";

const queryClient = new QueryClient();
/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const teamsContext = useTeamsContext(queryClient);

  return (
    <TeamsFxContext.Provider value={teamsContext}>
      <QueryClientProvider client={teamsContext.queryClient}>
        <FluentProvider theme={teamsContext.theme}>
          <Router>
            {
              <Routes>
                <Route
                  path="/dataprivacy"
                  element={
                    <TeamcenterAuthentication>
                      <DataPrivacy />
                    </TeamcenterAuthentication>
                  }
                />
                <Route
                  path="/dataprivacyinitial"
                  element={
                    <TeamcenterAuthentication>
                      <DataPrivacyInitial />
                    </TeamcenterAuthentication>
                  }
                />
                <Route
                  path="/problemreports"
                  element={
                    <TeamcenterAuthentication>
                      <ProblemReports />
                    </TeamcenterAuthentication>
                  }
                />
                <Route
                  path="/myworkspace"
                  element={
                    <TeamcenterAuthentication>
                      <ProblemReports />
                    </TeamcenterAuthentication>
                  }
                />
                <Route
                  path="/problemdetails/:internalName/:displayName"
                  element={
                    <TeamcenterAuthentication>
                      <ProblemDetails />
                    </TeamcenterAuthentication>
                  }
                />
                <Route
                  path="/problemreportinfo/:uid/:type/:value"
                  element={
                    <TeamcenterAuthentication>
                      <ProblemReportInfo />
                    </TeamcenterAuthentication>
                  }
                />
                <Route
                  path="/workflowtasks"
                  element={
                    <TeamcenterAuthentication>
                      <WorkflowTasks />
                    </TeamcenterAuthentication>
                  }
                />
                <Route
                  path="/workflowtaskinfo/:uid/:type"
                  element={
                    <TeamcenterAuthentication>
                      <WorkflowTaskInfo />
                    </TeamcenterAuthentication>
                  }
                />
                <Route
                  path="/teamcenterauthpopup"
                  element={<TeamcenterAuthPopup />}
                />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/termsofuse" element={<TermsOfUse />} />
              </Routes>
            }
          </Router>
          <TeamcenterError />
        </FluentProvider>
      </QueryClientProvider>
    </TeamsFxContext.Provider>
  );
}
